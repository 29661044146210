

import {
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonToggle,
    IonImg,
    IonRow,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonIcon,
    onIonViewDidEnter
} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import router from "@/router";
import {computed, defineComponent, ref} from "vue";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/vue';
import {presentValidationAlert} from "@/services/Notify";
import {execute} from "@/mixins/LoadingMixin";
import {useRoute} from "vue-router";
import {getTemplate, updateTemplate, createTemplate} from "@/services/TemplateService";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {createTabs, deleteTab, updateTab, updateTabTitles} from "@/services/TabService";
import {JourneyTab} from "@/models/JourneyTabModel";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {openSelectImageModal} from "@/services/SelectModalService";
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {Image} from "@/models/ImageModel";
import {getImage} from "@/services/ImagesService";
import {getTranslatedNumber} from "@/utils/NumberTranslate";
import {move , trash , closeOutline, add} from 'ionicons/icons';


export default defineComponent({
    name: 'TemplateName',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonLabel,
        IonInput,
        IonRadioGroup,
        IonRadio,
        IonToggle,
        IonImg,
        IonRow,
        IonGrid,
        IonCol,
        IonCard, 
        IonCardContent, 
        IonCardTitle,
        FixedFooter
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.templateName);
        this.template = {} as JourneyTemplate;
        if (this.templateId !== EMPTY_GUID) {
            router.currentRoute.value.meta.goBackTo = 'templates';
            
            await execute(async () => {
                this.template = await getTemplate(this.templateId);
                this.originalTabsCount = this.template.tabsCount;
                this.currentTabCount = this.template.tabsCount;
            },  this.language.errors.gettingTemplate);
        } else {
            router.currentRoute.value.meta.goBackTo = 'home';
            //this.template.id = uuid4();
            this.template.id = EMPTY_GUID;
            this.template.tabsCount = 0;
            this.template.name = '';
            this.template.tabs = [] as JourneyTab[];
        }
        if (!this.template.enableCustomContent) {
            this.template.enableCustomContent = false;
        }
//Reset the Icons Array
        if (this.tabIcons.length > 0){
            this.tabIcons.length = 0;
        }

        //Load tab icons
        for (let i = 0; i <= this.template.tabsCount - 1; i++){
            let tabIcon = '';

            if (this.template.tabs[i].tabIconId === EMPTY_GUID || this.template.tabs[i].tabIconId === undefined) {
                tabIcon = 'No Matching Image';
            }
            else {                
                tabIcon = this.template.tabs[i].tabIconId;
            }

            if (tabIcon != 'No Matching Image'){
                this.selectedIcon = await getImage(tabIcon);
                this.template.tabs[i].tabIconId = tabIcon;
            }
            else{              
                this.selectedIcon = {} as Image;
            }
            this.tabIcons.push(this.selectedIcon);
        }
        this.updateTabVisibility();
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const templateId = computed(() => route.params.id as string || EMPTY_GUID);
        const template = ref({} as JourneyTemplate);
        const originalTabsCount = ref(0);
        const editingExisting = computed(() => templateId.value !== EMPTY_GUID);
        const currentTabCount = ref(0);
        const disableContentToggle = computed(() => template.value.tabsCount < 3 );
        const selectedIcon = ref({} as Image);
        const tabIcons = ref<Image[]>([]);
        let tabsUpdated = false;
        

        const choiceVisible = (choiceIndex: number) => {
            let result = true; 
            if (template.value.id === EMPTY_GUID || template.value.inUseCnt === 0 ){
                return result;
            }
            switch(choiceIndex){
                case 1:
                    result = template.value.tabsCount < 3 ;
                    break;
                case 2:
                    result = template.value.tabsCount < 3 ;
                    break;
                case 3:
                    result = template.value.tabsCount > 2 ;
                    break;
                case 4:
                    result = template.value.tabsCount > 2 ;
                    break;
            }
            return result;
        }

        const updateTabVisibility = () =>{
            const tabCnt: number = template.value.tabsCount;
            template.value.tabs.forEach((tab: JourneyTab) => {
                switch(tab.tabNum){
                    case 1:
                        tab.toRemove = tabCnt > 1 ? false : true;
                        break;
                    case 2:
                        tab.toRemove = tabCnt > 2 ? false : true ;
                        break;
                    case 3:
                        tab.toRemove = tabCnt > 3 ? false : true ;
                        break;
                    case 4:
                        tab.toRemove = tabCnt > 0 ? false : true ;
                        break;
                }
            });
        }

        const addTab = (tabNum: number) => {
            const existingTab = template.value.tabs.find((tab: JourneyTab) => tab.tabNum === tabNum);
            if(existingTab){
                console.log(`Tab ${tabNum} already exists`);
                existingTab.toRemove = false;
                //addTabIcon(existingTab.tabIconId);
            }else{
                console.log(`Adding tab ${tabNum}`);
                template.value.tabs.push({
                        id: EMPTY_GUID,
                        title: '',
                        tabNum: tabNum,
                        templateId: templateId.value,
                        tabIconId: EMPTY_GUID
                    } as JourneyTab);
                //addTabIcon();
            }
        }

        const addTabs = () => {
            const currTabNums = template.value.tabs.map((tab: JourneyTab) => tab.tabNum);
            console.log(`Current tab nums: ${JSON.stringify(currTabNums)}`);
            if(template.value.tabs.length === 0){
                for(let i = 0 ; i < template.value.tabsCount ; i++){
                    const tabNum =  (i === template.value.tabsCount - 1) ? 4 : i + 1 ;
                    addTab(tabNum);
                }
            }else{
                if(template.value.tabs.length === 1 && template.value.tabsCount === 2){
                    addTab(4);
                    tabIcons.value.push({} as Image);
                }else{
                    if(template.value.tabsCount === 4){
                        console.log(` Tabs count is 4`);
                        if(!currTabNums.includes(1)) addTab(1);
                        if(!currTabNums.includes(2)) addTab(2);
                        if(!currTabNums.includes(3)) addTab(3); 
                        if(!currTabNums.includes(4)) addTab(4); 
                    }else if(template.value.tabsCount === 3){
                        console.log(` Tabs count is 3`);
                        if(!currTabNums.includes(1)) addTab(1);
                        if(!currTabNums.includes(2)) addTab(2);
                        if(!currTabNums.includes(4)) addTab(4); 
                    }
                    /*if(tabIcons.value.length > 2 && tabIcons.value.length === 3){
                        tabIcons.value.splice(2,0,{} as Image);
                    }else{
                        tabIcons.value.push({} as Image);
                    }*/
                }
                
            }
            template.value.tabs.sort((a , b) => a.tabNum - b.tabNum);
            updateTabVisibility();
            tabsUpdated = true;
        }
        

        const removeTab = (tabNum: number) => {
            template.value.tabs.forEach((tab: JourneyTab) => {
                if(tab.tabNum === tabNum){
                    tab.toRemove = true;
                }
            });
        }

        const removeTabs = () => {
            switch (template.value.tabsCount){
                case 1:
                    removeTab(1);
                    removeTab(2);
                    removeTab(3);
                    break;
                case 2:
                    removeTab(2);
                    removeTab(3);
                    break;
                case 3:
                    removeTab(3);
                    break;
            }
            updateTabVisibility();
            tabsUpdated = true;
        }

        const getFriendlyTabNum = (tabNum: number) => {
            const visibleTabs = template.value.tabs.filter((tab: JourneyTab) => tab.toRemove !== true);
            const tabIndex = visibleTabs.findIndex((tab: JourneyTab) => tab.tabNum === tabNum);
            return getTranslatedNumber(tabIndex + 1); 
        }

        const validateTabs = () => {
            const errorMessages: string[] = [];
            console.log(`Tabs:`);
            console.log(template.value.tabs);
            if (template.value.tabs.some((tab: JourneyTab) => (!tab.title || tab.title.trim() === '') && tab.toRemove !== true)) {
                errorMessages.push(language.value.validation.fillAllTitles);
            } 
            if (template.value.tabs.some((tab: JourneyTab) => (!tab.tabIconId || tab.tabIconId.trim() === EMPTY_GUID) && tab.toRemove !== true)) {
                
                errorMessages.push(language.value.validation.selectIcon);
            } 
            if(errorMessages.length > 0){
                presentValidationAlert(errorMessages.join("<br/>"));
            }
            return errorMessages.length === 0;
        }

        const tabCountSelected = async (e: any) => {
            template.value.tabsCount = parseInt(e.target.value, 10);
            console.log(`Selected tab count: ${template.value.tabsCount}`)
            if(template.value.tabsCount < 3){
                template.value.enableCustomContent = true;
            }
            if (template.value.tabs.length !== template.value.tabsCount ){
                if(template.value.tabs.length > template.value.tabsCount){
                    console.log("Removing tabs");
                    removeTabs();
                }else if (template.value.tabs.length < template.value.tabsCount){
                    console.log("Adding tabs");
                    addTabs();
                }
            }else{
                template.value.tabs.forEach((tab: JourneyTab) =>{ tab.toRemove = false;});
            }
        }

        const startCount = (tabNum: number) => {
            if (tabNum ==1){
                return true;
            }
            if (template.value.inUseCnt > 0){
                return (template.value.tabsCount > 2 && tabNum === 3) || (template.value.tabsCount < 3 && tabNum === 2) ; 
            }
            return false;
            
        }

        const endCount = (tabNum: number) => {
            if (tabNum == 4){
                return true;
            }
            if (template.value.inUseCnt > 0){
                return (template.value.tabsCount < 2 && tabNum === 2) ; 
            }
            return false;
        }
        
        const handleNext = async () => {
            if (validateTabs()){
                await execute(async () => {
                    if (template.value.id !== EMPTY_GUID) {
                        await updateTemplate({ id: template.value.id, name: template.value.name , tabsCount: template.value.tabsCount, complete: template.value.complete, appInstanceId: template.value.appInstanceId, enableCustomContent: template.value.enableCustomContent });
                        const tabsToCreate: JourneyTab[] = template.value.tabs.filter((tab: JourneyTab) => (tab.id === EMPTY_GUID && tab.toRemove !== true));
                        console.log(`Tabs to CREATE`);
                        console.log(tabsToCreate);
                        const tabsToUpdate: JourneyTab[] = template.value.tabs.filter((tab: JourneyTab) => (tab.id !== EMPTY_GUID && tab.toRemove !== true));
                        console.log(`Tabs to UPDATE`);
                        console.log(tabsToUpdate);
                        const tabsToDelete: JourneyTab[] = template.value.tabs.filter((tab: JourneyTab) => (tab.id !== EMPTY_GUID && tab.toRemove === true));
                        console.log(`Tabs to DELETE`);
                        console.log(tabsToDelete);
                        
                        if (tabsToUpdate.length > 0){
                            await updateTabTitles(tabsToUpdate);
                        }
                        if (tabsToCreate.length > 0){
                            tabsToCreate.forEach((tab: JourneyTab) => tab.id = uuid4())
                            await createTabs(tabsToCreate);
                        }
                        tabsToDelete.forEach((tab: JourneyTab) => deleteTab(tab.id))
                    } else {
                        template.value.id = uuid4();
                        //in case the user has changed tab counts during the process remove those they don't actually want
                        template.value.tabs = template.value.tabs.filter((tab: JourneyTab) => tab.toRemove == false);
                        template.value.tabs.forEach((tab: JourneyTab) => tab.templateId = template.value.id);
                        template.value.tabs.forEach((tab: JourneyTab) => tab.id = uuid4());
                        await createTemplate({ id: template.value.id, name: template.value.name , tabsCount: template.value.tabsCount, complete: false, appInstanceId: store.getters['app/selectedAppInstanceId'], enableCustomContent: template.value.enableCustomContent });
                        await createTabs(template.value.tabs);
                    }
                    await router.push({ name: 'tab', params: {id: template.value.id , tabId: template.value.tabs[0].id }});
                }, language.value.errors.savingTemplate);
            }
        }

        

        const openImageSelectModal = async (index: number) => {
            const imageSelect = await openSelectImageModal(IMAGE_GROUP_TYPES.get(ImageGroupId.JourneySetIcon) || '', ImageGroupId.JourneySetIcon);
            const { data } = await imageSelect.onWillDismiss();
            if(data.id){ 
                data.title = template.value.tabs[index].title;
                template.value.tabs[index].tabIconId = data.id;
                template.value.tabs[index].icon = data.data;
                if(!tabIcons.value.find((iconImg: Image) => iconImg.id === data.id)){
                    tabIcons.value.push(data);
                }
            }
        }

        const removeImg = (tab: JourneyTab) => {
            tabIcons.value = tabIcons.value.filter(tabIcon => tabIcon.id !== tab.tabIconId);
            tab.tabIconId = EMPTY_GUID;
            tab.icon = 'remove';
        }

        const iconExists = (tabIconId: string) => {
            const iconImage = tabIcons.value.find((icon: Image) => icon.id === tabIconId);
            if(iconImage){
                return true;
            }
            return false;
        }

        const getTabImageData = (tabIconId: string) =>{
            if(tabIconId !== EMPTY_GUID){
                const iconImage = tabIcons.value.find((icon: Image) => icon.id === tabIconId);
                if(iconImage){
                    return iconImage.data;
                }
            }
            return "";
        }

        return {
            store,
            language,
            template,
            templateId,
            footerButtons: computed(() =>
                new FooterButtonsBuilder()
                    .addClearBackButton()
                    .addPrimaryNextButton(async () => await handleNext())
                    .create()
            ),
            radioSelected: (e: any) => template.value.tabsCount = parseInt(e.target.value, 10),
            tabCountSelected,
            originalTabsCount,
            currentTabCount,
            disableContentToggle,
            openImageSelectModal,
            selectedIcon,
            tabIcons,
            removeImg,
            tabsUpdated,
            iconExists,
            getTabImageData,
            choiceVisible,
            getTranslatedNumber,
            getFriendlyTabNum,
            updateTabVisibility,
            move,
            trash,
            closeOutline,
            startCount,
            endCount
        }
    }
});
